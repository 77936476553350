import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(20.300000, 25.000000)">
          <path
            d="M14.954 36.181c-5.21-1.11-9.12-4.739-9.35-8.677L5.521 26.084l2.936-.111 2.935-.111.737 1.444c1.358 2.66 3.579 3.878 7.101 3.894 5.139.023 8.506-4.285 5.612-7.179-1.149-1.149-2.939-1.801-8.467-3.087-5.707-1.327-8.38-3.028-9.69-6.166-1.553-3.718.173-8.518 3.931-10.93C16.812-.138 26.772 1.281 30.244 6.636c1.115 1.719 1.902 4.205 1.59 5.019-.149.388-.963.531-3.031.531h-2.828l-.897-1.809c-1.466-2.956-4.922-4.198-8.732-3.14-2.695.749-4.208 3.848-2.873 5.885.899 1.372 2.344 1.999 7.581 3.294 2.557.632 5.344 1.457 6.193 1.832 3.807 1.683 5.783 4.814 5.397 8.553C32.047 32.585 27.436 36.079 19.992 36.384c-1.885.077-4.152-.014-5.038-.203z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;